import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    base_api: window.location.hostname == 'localhost' ? 'http://localhost:8000/api/' : `https://api.thewinone888.com/api/`,
    base_url : window.location.hostname == 'localhost' ? 'http://localhost:8080' : `https://runhdp.thewinone888.com/`,
    authen : {},
    header_bearer : {'Authorization': `Bearer ${localStorage.getItem('tokenAccess')}`},
    req : Axios.create({
        baseURL: '/',
        timeout: 0,
        headers: {'Authorization': `Bearer ${localStorage.getItem('tokenAccess')}`}
    }),
    barColor: 'rgba(0, 0, 0, .8, rgba(0, 0, 0, .8',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    overlay: false,
  },
  mutations: {
    set_auth(state,value){
            state.authen = value;
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload
    },
  },
  actions: {

  },

  getters: {
    req: state => state.req,
    base_api : state => state.base_api,
    base_url : state => state.base_url,
    authen : state => state.authen,
    header : state => state.header_bearer,
    overlay : state => state.overlay,
  }
})